.popular {
  margin-bottom: 80px;

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
  &__title {
    // margin-bottom: ;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 26px;

    @media (max-width: 1439px) {
      gap: 16px;
    }

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      gap: 8px;
      overflow-x: scroll;
      margin: 0 -15px 0 -15px;
      padding-left: 15px;
      justify-content: flex-start;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    border-radius: 8px;
    background-color: $blue-blue-100;

    height: 140px;

    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    text-align: center;
    color: $white-100;

    flex: 0 0 calc(20% - 22px);

    @media (max-width: 1439px) {
      flex: 0 0 calc(33% - 10px);
    }

    @media (max-width: 767px) {
      flex: 0 0 calc(92px);
      height: 84px;
      gap: 8px;
      font-size: 12px;
    }

    & > img {
      width: 40px;
    }
  }
}
