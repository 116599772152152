/* stylelint-disable */
@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-family: "#{$font-family}";
    src: url("../fonts/#{$url}.woff2") format("woff2");
    font-weight: #{$weight};
    font-display: swap;
    font-style: $style;
  }
}

@mixin font_header($color) {
  font-family: $font-family-medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: $color;
}
