.bookmakers {
  margin-bottom: 80px;

  &-container {
    @media(max-width: 767px) {
      margin-right: -30px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 26px;

    @media (max-width: 1439px) {
      gap: 16px;
    }

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      width: 100%;
      overflow-x: scroll;
      justify-content: flex-start;
      margin: 0 -15px 0 -15px;
      padding-left: 15px;
      margin-bottom: 8px;

      gap: 8px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    border-radius: 8px;
    background-color: $blue-blue-6;

    height: 140px;
    padding: 35px;
    color: $white-100;

    flex: 0 0 calc(20% - 22px);

    @media (max-width: 1439px) {
      flex: 0 0 calc(33% - 10px);
    }

    @media (max-width: 767px) {
      // flex: 0 0 calc(20% - 10px);
      flex-basis: 152px;
      height: 72px;
    }

    & > img {
      width: 120px;
    }
  }
}

.mob {
  display: none !important;
}

@media (max-width: 767px) {
  .mob {
    display: flex !important;
  }

  .desk {
    display: none !important;
  }
}
