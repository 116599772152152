.btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #271c51 !important;


  &-main {
    padding: 4px 12px;
    background-color: $yellow-100;
    border-radius: 4px;
  }

  &-full-screen {
    background-color: $yellow-100;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
    padding: 18.5px;
    margin-bottom: 40px;

    @media(max-width: 767px) {
      margin-bottom: 20px
    }
  }

  &-main-big {
    padding: 12px 40px !important;
    background-color: $yellow-100;
    border-radius: 8px;
    display: inline-block;
    padding: 18.5px;

    @media (max-width: 1439px) {
      // margin-bottom: 50px;
    }
  }
}
