.first-screen {
  margin-bottom: 100px;

  @media (max-width: 1439px) {
    margin-bottom: 80px;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  &-swiper {
    width: 100%;
    height: 100%;

    margin-bottom: 20px;

    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }

  &-slide {
    // padding: 60px 0 0 60px;
    background-color: $blue-blue-100;

    border-radius: 8px;

    display: flex;
    // align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &__text {
      padding: 60px 0 0 60px;
      max-width: 300px;

      @media (max-width: 1439px) {
        padding: 60px 0 0 40px;
      }

      @media (max-width: 767px) {
        max-width: none;
        text-align: center;
        margin-bottom: 25px;
        padding: 60px 40px 0 40px;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 34px;
      line-height: 105%;
      letter-spacing: -0.02em;
      color: #d9ed08;

      margin: 0;
      margin-bottom: 16px;
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 145%;
      color: $white-80;

      margin: 0;
    }

    &__images {
      flex-basis: 657px;
      height: 320px;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      background-image: url(../img/slider/bg.png);

      @media (max-width: 767px) {
        height: auto;
        flex-basis: auto;
        background-position: bottom 10px left 10px;
        background-repeat: no-repeat;
        background-size: calc(100% - 20px);
        background-image: url(../img/slider/bg1.png);

        &.bg-alt {
          background-image: url(../img/slider/bg2.png);
        }

        &.first-slide {
          & > img {
            transform: translateX(-5%);
          }
        }
      }

      & > img {
        margin-top: auto;
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 60px;
    left: 60px;

    display: flex;
    align-items: center;

    @media (max-width: 1439px) {
      bottom: 40px;
      left: 40px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      flex: 0 0 calc(50% - 15px);
      gap: 8px;
    }
  }
  &__item {
    flex: 0 0 calc(25% - 15px);

    padding: 20px 30px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    border-radius: 8px;
    background-color: $blue-blue-6;

    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    text-align: center;
    color: #271c51;

    @media (max-width: 1439px) {
      padding-right: 12px;
    }

    @media (max-width: 767px) {
      flex: 0 0 calc(50% - 4px);
      font-size: 14px;
      padding-left: 20px;
    }
  }
}

.swiper-pagination {
  position: static;
  width: auto !important;
  margin-left: 40px;

  @media (max-width: 767px) {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}

.swiper-pagination-bullet-active {
  background: $yellow-100;
}

.swiper-button-next,
.swiper-button-prev {
  position: static;
  margin: 0;

  @media (max-width: 767px) {
    display: none;
  }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url(../img/slider/btn-active.svg);
  background-size: 100% 100%;
  transform: rotate(180deg);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url(../img/slider/btn-active.svg);
  background-size: 100% 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  width: 32px;
  height: 32px;
}

.swiper-button-next {
  margin-left: 10px;
}

.swiper-button-next.swiper-button-disabled:after,
.swiper-button-prev.swiper-button-disabled:after {
  background-image: url(../img/slider/btn-dis.svg);
}

.swiper-button-next.swiper-button-disabled:after {
  transform: rotate(180deg);
}

.swiper-button-prev.swiper-button-disabled {
  transform: rotate(180deg);
}
