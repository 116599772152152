html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: $font-family-medium, sans-serif;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 $container-offset;
  max-width: $container-width;

  @media (min-width: 1281px) {
    max-width: 100%;
  }

  @media (max-width: 1240px) {
    padding: 0 24px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #222;
    opacity: 0.5;
  }
}

.page--ios .dis-scroll {
  position: relative;
}

.content-title {
  font-weight: 500;
  font-size: 34px;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #271c51;
  margin: 0;
  margin-bottom: 40px;

  @media(max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
