// если вы хотите использовать sass-переменные - удалите root
// colors

// base
$font-family-semibold: "Onest SemiBold", sans-serif;
$font-family-medium: "Onest Medium", sans-serif;
$font-family-regular: "Onest Regular", sans-serif;


$content-width: 1281px;
$container-offset: 40px;
$container-width: calc($content-width + ($container-offset * 2));

//colors

$white-100: #fff;
$white-80: rgba(255, 255, 255, 0.8);
$yellow-100: #d9ed08;
$blue-blue-100: #271c51;
$blue-blue-80: #524974;
$blue-blue-60: #7a7394;
$blue-blue-12: #e5e4ea;
$blue-blue-6: #f2f1f5;
$blue-blue-4: #f6f6f8;
