.header {
  background-color: $blue-blue-100;
  border-radius: 0 0 8px 8px;

  margin-bottom: 30px;

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-logo {
    max-width: 70px;

    & > svg {
      width: 70px;
      height: 14px;
    }
  }

  &-nav {
    &__list {
      display: flex;
      align-items: center;
      gap: 40px;

      @media(max-width: 767px) {
        gap: 12px;
      }
    }

    &__item {
      & > a {
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        text-align: center;
        color: $white-100;

        @media(max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}

.footer {
  padding: 26px;
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    text-align: center;
    color: #271c51;
    opacity: 0.8;

    @media(max-width: 767px) {
      font-size: 12px;
    }
  }
}
