.last-screen {

  &-swiper {
    width: 100%;
    height: 100%;
  }

  &-slide {
    // padding: 60px 0 0 60px;
    background-color: $blue-blue-100;

    border-radius: 8px;

    display: flex;
    // align-items: center;
    justify-content: space-between;


    @media(max-width: 767px) {
      flex-direction: column;
    }

    &__text {
      padding: 60px 0 0 60px;
      max-width: 500px;

      @media (max-width: 1439px) {
        padding: 50px 0 0 40px;
      }

      @media(max-width: 767px) {
        padding: 40px 40px 0 40px;
        text-align: center;
        margin-bottom: 50px;
        max-width: none;
       }
    }
    &__title {
      font-weight: 500;
      font-size: 34px;
      line-height: 105%;
      letter-spacing: -0.02em;
      color: #d9ed08;

      margin: 0;
      margin-bottom: 16px;

      @media(max-width: 767px) {
        margin-bottom: 8px;
       }

      & > span {
        color: $white-100;
      }
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 145%;
      color: $white-80;
      margin: 0;
      margin-bottom: 30px;
    }

    &__images {
      flex-basis: 657px;
      height: 320px;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      background-image: url(../img/slider/bg.png);
      background-repeat: no-repeat;
      background-position: center left 240px;

      @media (max-width: 1439px) {
        background-position: center left;
      }

      @media(max-width: 767px) {
        flex-basis: auto;
        height: auto;

        background-image: url(../img/slider/bg1.png);
        background-size: calc(100%);
        background-position: left bottom 10px;
        margin: 0 10px;
      }

      & > img {
        margin-top: auto;
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 60px;
    left: 60px;

    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__item {
    flex: 0 0 calc(25% - 15px);

    padding: 20px 30px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    border-radius: 8px;
    background-color: $blue-blue-6;
  }
}
