/* stylelint-disable */
.btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #271c51 !important;
}
.btn-main {
  padding: 4px 12px;
  background-color: #d9ed08;
  border-radius: 4px;
}
.btn-full-screen {
  background-color: #d9ed08;
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  padding: 18.5px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .btn-full-screen {
    margin-bottom: 20px;
  }
}
.btn-main-big {
  padding: 12px 40px !important;
  background-color: #d9ed08;
  border-radius: 8px;
  display: inline-block;
  padding: 18.5px;
}
/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Onest Regular";
  src: url("../fonts/../fonts/Onest-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Onest Medium";
  src: url("../fonts/../fonts/Onest-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Onest SemiBold";
  src: url("../fonts/../fonts/Onest-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: "Onest Medium", sans-serif, sans-serif;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1361px;
}
@media (min-width: 1281px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 1240px) {
  .container {
    padding: 0 24px;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}
.dis-scroll::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #222;
  opacity: 0.5;
}

.page--ios .dis-scroll {
  position: relative;
}

.content-title {
  font-weight: 500;
  font-size: 34px;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #271c51;
  margin: 0;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .content-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.header {
  background-color: #271c51;
  border-radius: 0 0 8px 8px;
  margin-bottom: 30px;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-logo {
  max-width: 70px;
}
.header-logo > svg {
  width: 70px;
  height: 14px;
}
.header-nav__list {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767px) {
  .header-nav__list {
    gap: 12px;
  }
}
.header-nav__item > a {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #fff;
}
@media (max-width: 767px) {
  .header-nav__item > a {
    font-size: 12px;
  }
}

.footer {
  padding: 26px;
}
.footer__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #271c51;
  opacity: 0.8;
}
@media (max-width: 767px) {
  .footer__text {
    font-size: 12px;
  }
}

.first-screen {
  margin-bottom: 100px;
}
@media (max-width: 1439px) {
  .first-screen {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .first-screen {
    margin-bottom: 40px;
  }
}
.first-screen-swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .first-screen-swiper {
    margin-bottom: 12px;
  }
}
.first-screen-slide {
  background-color: #271c51;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .first-screen-slide {
    flex-direction: column;
  }
}
.first-screen-slide__text {
  padding: 60px 0 0 60px;
  max-width: 300px;
}
@media (max-width: 1439px) {
  .first-screen-slide__text {
    padding: 60px 0 0 40px;
  }
}
@media (max-width: 767px) {
  .first-screen-slide__text {
    max-width: none;
    text-align: center;
    margin-bottom: 25px;
    padding: 60px 40px 0 40px;
  }
}
.first-screen-slide__title {
  font-weight: 500;
  font-size: 34px;
  line-height: 105%;
  letter-spacing: -0.02em;
  color: #d9ed08;
  margin: 0;
  margin-bottom: 16px;
}
.first-screen-slide__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}
.first-screen-slide__images {
  flex-basis: 657px;
  height: 320px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: url(../img/slider/bg.png);
}
@media (max-width: 767px) {
  .first-screen-slide__images {
    height: auto;
    flex-basis: auto;
    background-position: bottom 10px left 10px;
    background-repeat: no-repeat;
    background-size: calc(100% - 20px);
    background-image: url(../img/slider/bg1.png);
  }
  .first-screen-slide__images.bg-alt {
    background-image: url(../img/slider/bg2.png);
  }
  .first-screen-slide__images.first-slide > img {
    transform: translateX(-5%);
  }
}
.first-screen-slide__images > img {
  margin-top: auto;
}
.first-screen__button {
  position: absolute;
  bottom: 60px;
  left: 60px;
  display: flex;
  align-items: center;
}
@media (max-width: 1439px) {
  .first-screen__button {
    bottom: 40px;
    left: 40px;
  }
}
.first-screen__list {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .first-screen__list {
    flex-wrap: wrap;
    flex: 0 0 calc(50% - 15px);
    gap: 8px;
  }
}
.first-screen__item {
  flex: 0 0 calc(25% - 15px);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 8px;
  background-color: #f2f1f5;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  color: #271c51;
}
@media (max-width: 1439px) {
  .first-screen__item {
    padding-right: 12px;
  }
}
@media (max-width: 767px) {
  .first-screen__item {
    flex: 0 0 calc(50% - 4px);
    font-size: 14px;
    padding-left: 20px;
  }
}

.swiper-pagination {
  position: static;
  width: auto !important;
  margin-left: 40px;
}
@media (max-width: 767px) {
  .swiper-pagination {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}

.swiper-pagination-bullet-active {
  background: #d9ed08;
}

.swiper-button-next,
.swiper-button-prev {
  position: static;
  margin: 0;
}
@media (max-width: 767px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url(../img/slider/btn-active.svg);
  background-size: 100% 100%;
  transform: rotate(180deg);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url(../img/slider/btn-active.svg);
  background-size: 100% 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  width: 32px;
  height: 32px;
}

.swiper-button-next {
  margin-left: 10px;
}

.swiper-button-next.swiper-button-disabled:after,
.swiper-button-prev.swiper-button-disabled:after {
  background-image: url(../img/slider/btn-dis.svg);
}

.swiper-button-next.swiper-button-disabled:after {
  transform: rotate(180deg);
}

.swiper-button-prev.swiper-button-disabled {
  transform: rotate(180deg);
}

.popular {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .popular {
    margin-bottom: 40px;
  }
}
.popular__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 26px;
}
@media (max-width: 1439px) {
  .popular__list {
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .popular__list {
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: scroll;
    margin: 0 -15px 0 -15px;
    padding-left: 15px;
    justify-content: flex-start;
  }
}
.popular__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
  background-color: #271c51;
  height: 140px;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  color: #fff;
  flex: 0 0 calc(20% - 22px);
}
@media (max-width: 1439px) {
  .popular__item {
    flex: 0 0 calc(33% - 10px);
  }
}
@media (max-width: 767px) {
  .popular__item {
    flex: 0 0 92px;
    height: 84px;
    gap: 8px;
    font-size: 12px;
  }
}
.popular__item > img {
  width: 40px;
}

.bookmakers {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .bookmakers-container {
    margin-right: -30px;
  }
}
@media (max-width: 767px) {
  .bookmakers {
    margin-bottom: 40px;
  }
}
.bookmakers__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 26px;
}
@media (max-width: 1439px) {
  .bookmakers__list {
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .bookmakers__list {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    justify-content: flex-start;
    margin: 0 -15px 0 -15px;
    padding-left: 15px;
    margin-bottom: 8px;
    gap: 8px;
  }
}
.bookmakers__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
  background-color: #f2f1f5;
  height: 140px;
  padding: 35px;
  color: #fff;
  flex: 0 0 calc(20% - 22px);
}
@media (max-width: 1439px) {
  .bookmakers__item {
    flex: 0 0 calc(33% - 10px);
  }
}
@media (max-width: 767px) {
  .bookmakers__item {
    flex-basis: 152px;
    height: 72px;
  }
}
.bookmakers__item > img {
  width: 120px;
}

.mob {
  display: none !important;
}

@media (max-width: 767px) {
  .mob {
    display: flex !important;
  }
  .desk {
    display: none !important;
  }
}
.last-screen-swiper {
  width: 100%;
  height: 100%;
}
.last-screen-slide {
  background-color: #271c51;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .last-screen-slide {
    flex-direction: column;
  }
}
.last-screen-slide__text {
  padding: 60px 0 0 60px;
  max-width: 500px;
}
@media (max-width: 1439px) {
  .last-screen-slide__text {
    padding: 50px 0 0 40px;
  }
}
@media (max-width: 767px) {
  .last-screen-slide__text {
    padding: 40px 40px 0 40px;
    text-align: center;
    margin-bottom: 50px;
    max-width: none;
  }
}
.last-screen-slide__title {
  font-weight: 500;
  font-size: 34px;
  line-height: 105%;
  letter-spacing: -0.02em;
  color: #d9ed08;
  margin: 0;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .last-screen-slide__title {
    margin-bottom: 8px;
  }
}
.last-screen-slide__title > span {
  color: #fff;
}
.last-screen-slide__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  margin-bottom: 30px;
}
.last-screen-slide__images {
  flex-basis: 657px;
  height: 320px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: url(../img/slider/bg.png);
  background-repeat: no-repeat;
  background-position: center left 240px;
}
@media (max-width: 1439px) {
  .last-screen-slide__images {
    background-position: center left;
  }
}
@media (max-width: 767px) {
  .last-screen-slide__images {
    flex-basis: auto;
    height: auto;
    background-image: url(../img/slider/bg1.png);
    background-size: 100%;
    background-position: left bottom 10px;
    margin: 0 10px;
  }
}
.last-screen-slide__images > img {
  margin-top: auto;
}
.last-screen__button {
  position: absolute;
  bottom: 60px;
  left: 60px;
  display: flex;
  align-items: center;
}
.last-screen__list {
  display: flex;
  align-items: center;
  gap: 20px;
}
.last-screen__item {
  flex: 0 0 calc(25% - 15px);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 8px;
  background-color: #f2f1f5;
}